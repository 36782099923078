<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col>
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card no-body class="card-statistics">
          <b-card-header>
            <b-card-title>{{ $t("Order") }}</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              <b-dropdown
                :text="selectedOrderFilter"
                size="sm"
                class="budget-dropdown"
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="year in ['Last Week', 'Last Month', 'Last Year']"
                  :key="year"
                  v-on:click="fetchOrderStatistics(year)"
                >
                  {{ $t(year) }}
                </b-dropdown-item>
              </b-dropdown>
            </b-card-text>
          </b-card-header>
          <vue-apex-charts
            type="line"
            height="300"
            :options="orderChartOptions"
            :series="order_series"
          ></vue-apex-charts>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body class="card-statistics">
          <b-card-header>
            <b-card-title>{{ $t("Quotation") }}</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              <b-dropdown
                :text="selectedQuotationFilter"
                size="sm"
                class="budget-dropdown"
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="year in ['Last Week', 'Last Month', 'Last Year']"
                  :key="year"
                  v-on:click="fetchQuotationStatistics(year)"
                >
                  {{ $t(year) }}
                </b-dropdown-item>
              </b-dropdown>
            </b-card-text>
          </b-card-header>
          <vue-apex-charts
            type="line"
            height="300"
            :options="quotationChartOptions"
            :series="quotation_series"
          ></vue-apex-charts>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card no-body class="card-statistics">
          <b-card-header>
            <b-card-title>{{ $t("Recovery") }}</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              <b-dropdown
                :text="selectedRecoveryFilter"
                size="sm"
                class="budget-dropdown"
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="year in ['Last Week', 'Last Month', 'Last Year']"
                  :key="year"
                  v-on:click="fetchRecoveryStatistics(year)"
                >
                  {{ $t(year) }}
                </b-dropdown-item>
              </b-dropdown>
            </b-card-text>
          </b-card-header>
          <vue-apex-charts
            type="line"
            height="300"
            :options="recoveryChartOptions"
            :series="recovery_series"
          ></vue-apex-charts>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body class="card-statistics">
          <b-card-header>
            <b-card-title>{{ $t("Situation") }}</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              <b-dropdown
                :text="selectedSituationFilter"
                size="sm"
                class="budget-dropdown"
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="year in ['Last Week', 'Last Month', 'Last Year']"
                  :key="year"
                  v-on:click="fetchSituationStatistics(year)"
                >
                  {{ $t(year) }}
                </b-dropdown-item>
              </b-dropdown>
            </b-card-text>
          </b-card-header>
          <vue-apex-charts
            type="line"
            height="300"
            :options="situationChartOptions"
            :series="situation_series"
          ></vue-apex-charts>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardText,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCardHeader,
    BCardTitle,
    BCol,
    VueApexCharts,
    BCard,
    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    BDropdown,
    BCardText,
    BDropdownItem,
  },
  methods: {
    async fetchYearStatistics(filter) {
      this.selectedFilter = filter;

      try {
        const { data } = await instance.get("/week-statistics/");

        this.order_series = [{ data: data.order_data.map((e) => e.x) }];
        this.quotation_series = [{ data: data.quotation_data.map((e) => e.x) }];
        this.recovery_series = [{ data: data.recovery_data.map((e) => e.x) }];
        this.situation_series = [{ data: data.situation_data.map((e) => e.x) }];

        this.orderChartOptions = {
          ...this.orderChartOptions,
          xaxis: {
            categories: data.order_data.map((e) => e.y),
          },
        };

        this.quotationChartOptions = {
          ...this.quotationChartOptions,
          xaxis: {
            categories: data.quotation_data.map((e) => e.y),
          },
        };
        this.situationChartOptions = {
          ...this.situationChartOptions,
          xaxis: {
            categories: data.situation_data.map((e) => e.y),
          },
        };
        this.recoveryChartOptions = {
          ...this.recoveryChartOptions,
          xaxis: {
            categories: data.recovery_data.map((e) => e.y),
          },
        };
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async fetchSituationStatistics(filter) {
      this.selectedSituationFilter = filter;

      try {
        const { data } = await instance.get("/situation-statistics/", {
          params: {
            filter_key: filter.toLowerCase().split(" ").join("_"),
          },
        });

        this.situation_series = [{ data: data.map((e) => e.x) }];

        this.situationChartOptions = {
          ...this.situationChartOptions,
          xaxis: {
            categories: data.map((e) => e.y),
          },
        };
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async fetchRecoveryStatistics(filter) {
      this.selectedRecoveryFilter = filter;

      try {
        const { data } = await instance.get("/recovery-statistics/", {
          params: {
            filter_key: filter.toLowerCase().split(" ").join("_"),
          },
        });
        this.recovery_series = [{ data: data.map((e) => e.x) }];

        this.recoveryChartOptions = {
          ...this.recoveryChartOptions,
          xaxis: {
            categories: data.map((e) => e.y),
          },
        };
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async fetchQuotationStatistics(filter) {
      this.selectedQuotationFilter = filter;

      try {
        const { data } = await instance.get("/quotation-statistics/", {
          params: {
            filter_key: filter.toLowerCase().split(" ").join("_"),
          },
        });

        this.quotation_series = [{ data: data.map((e) => e.x) }];

        this.quotationChartOptions = {
          ...this.quotationChartOptions,
          xaxis: {
            categories: data.map((e) => e.y),
          },
        };
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async fetchOrderStatistics(filter) {
      this.selectedOrderFilter = filter;

      try {
        const { data } = await instance.get("/order-statistics/", {
          params: {
            filter_key: filter.toLowerCase().split(" ").join("_"),
          },
        });
        this.order_series = [{ data: data.map((e) => e.x) }];

        this.orderChartOptions = {
          ...this.orderChartOptions,
          xaxis: {
            categories: data.map((e) => e.y),
          },
        };
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  data() {
    return {
      data: {},
      selectedFilter: "Last Week",
      selectedOrderFilter: "Last Week",
      selectedQuotationFilter: "Last Week",
      selectedRecoveryFilter: "Last Week",
      selectedSituationFilter: "Last Week",

      order_series: [],
      recovery_series: [],
      quotation_series: [],
      situation_series: [],
      orderChartOptions: {
        colors: ["#7367f0"],

        chart: {
          height: 300,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
      },
      situationChartOptions: {
        colors: ["#00cfe8"],

        chart: {
          height: 300,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
      },
      recoveryChartOptions: {
        colors: ["#ea5455"],

        chart: {
          height: 300,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
      },
      quotationChartOptions: {
        colors: ["#28c76f"],

        chart: {
          height: 300,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
      },
    };
  },
  async created() {
    await this.fetchYearStatistics(this.selectedFilter);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
