<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>{{ $t("Statistics") }}</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        <b-dropdown
          :text="selectedFilter"
          size="sm"
          class="budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="year in ['Today', 'Last Week', 'Last Month', 'Last Year']"
            :key="year"
            v-on:click="fetchStatistics(year)"
          >
            {{ $t(year) }}
          </b-dropdown-item>
        </b-dropdown>
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="ClipboardIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ $t("Order") }}</h4>
              <b-card-text class="font-small-3 mb-0">
                {{ orderCount }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success">
                <feather-icon size="24" icon="CreditCardIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ $t("Quotation") }}</h4>
              <b-card-text class="font-small-3 mb-0">
                {{ quotationCount }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-danger">
                <feather-icon size="24" icon="DollarSignIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ $t("Recovery") }}</h4>
              <b-card-text class="font-small-3 mb-0">
                {{ recoveryCount }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="3" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-info">
                <feather-icon size="24" icon="BarChartIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ $t("Situation") }}</h4>
              <b-card-text class="font-small-3 mb-0">
                {{ situationCount }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,

    BDropdown,
    BDropdownItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedFilter: "Today",
      orderCount: null,
      recoveryCount: null,
      quotationCount: null,
      situationCount: null,
    };
  },
  async created() {
    await this.fetchStatistics(this.selectedFilter);
  },
  methods: {
    async fetchStatistics(filter) {
      this.selectedFilter = filter;

      this.orderCount = null;
      this.recoveryCount = null;
      this.quotationCount = null;
      this.situationCount = null;

      try {
        const {
          data: {
            order_count,
            quotation_count,
            situation_count,
            recovery_count,
          },
        } = await instance.get("/statistics/", {
          params: {
            filter_key: filter.toLowerCase().split(" ").join("_"),
          },
        });

        this.orderCount = order_count;
        this.recoveryCount = recovery_count;
        this.quotationCount = quotation_count;
        this.situationCount = situation_count;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>
